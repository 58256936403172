import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';
import sha256 from 'js-sha256';
import './App.css';

function App() {
  let [searchQuery, setSearchQuery] = useState('');
  let [musicData, setMusicData] = useState([]);

  useEffect(() => {

    axios
    .get('https://phils-music-list.herokuapp.com/api/music/getAll')
    .then((res) => {
      setMusicData(res.data);
    });
  }, []);

  document.addEventListener("keypress", (e) => {
    if (e.key === 'Enter' && document.getElementById("pass1").matches(":focus")) {
      e.preventDefault();
      Authenticate();
    }
  })

  if (!localStorage.getItem('isAuthenticated')) {
    localStorage.setItem('isAuthenticated', false);
  }

  var isAuthenticated = true;


  return (
    <div className="container">
      {isAuthenticated === 'false' && LoginButton()}
      <header className="header">
        <div className="title">
          Music List
        </div>
        <textarea id="search-box" rows="1" onChange={(e) => {setSearchQuery(e.target.value)}}>
        </textarea>
      </header>
      {<MusicList 
        searchQuery={searchQuery}
        musicData={musicData}
        isAuthenticated={isAuthenticated}
      />}
      {!isAuthenticated && LoginWindow()}
     
      
    </div>
  );
}

export default App;


function MusicList({searchQuery, musicData, isAuthenticated}) {
  var queryThreshold = 1.0;

  if (searchQuery !== "") {
    queryThreshold = 0.2;
  }

  const options = {
    threshold: queryThreshold,
    ignoreLocation: true,
    keys: ['Title', 'Author']
  }

  const fuse = new Fuse(musicData, options);
  const tempSearchResult = fuse.search(searchQuery);

  const result = (tempSearchResult.length === 0 && searchQuery === "") ? musicData.map((e) => ({item: e})) : tempSearchResult;

  if (result.length === 0) {
    return (<div>There's nothing here!</div>)
  }

  return (
    <div className="music-list">
      <div className="music-card">
        <div className="music-card-book"><b>Book</b></div>
        <div className="music-card-page"><b>Page</b></div>
        <div className="music-card-title"><b>Title</b></div>
        <div className="music-card-artist"><b>Artist</b></div>
      </div>
      {isAuthenticated && AddItemSection()}
      {result.map((entry) => (
        <MusicItemCard
        key={entry.item._id}
        id={entry.item._id}
        book={entry.item.Book}
        page={entry.item.Page}
        title={entry.item.Title}
        artist={entry.item.Author}
        isAuthenticated={isAuthenticated}
        />
      ))}
    </div>
  )
}

function MusicItemCard({id, book, page, title, artist, isAuthenticated}) {
  const [isOpen, setOpen] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const cardRef = useRef(null);

  const [newBook, setBook] = useState(book);
  const [newPage, setPage] = useState(page);
  const [newTitle, setTitle] = useState(title);
  const [newArtist, setArtist] = useState(artist);
  const [item, setItem] = useState({book, page, title, artist});

  const clickButton = (setStateFunc) => {
    setStateFunc(true);
    setTimeout(() => setStateFunc(false), 1200);
  }

  const updateItem = () => {
    setItem(
    {
      book: newBook,
      page: newPage,
      title: newTitle,
      artist: newArtist,
    })
    setOpen(false);

    axios.post(
      'https://phils-music-list.herokuapp.com/api/music/updateSong',
      {
        "id": id,
        "object":
        {
          "Book": parseInt(newBook),
          "Page": parseInt(newPage),
          "Title": newTitle,
          "Author": newArtist,
        }
      }
    ).then((res) => {
      console.log(res);
    })
  }

  const deleteItem = () => {
    axios.post(
      'https://phils-music-list.herokuapp.com/api/music/deleteSong',
      {
        "id": id,
      }
    ).then((res) => {
      console.log(res);
    })
    cardRef.current.style.display = 'none';
  }

  return (
    <div ref={cardRef} style={{ margin: (isOpen ? '10px 0 10px' : '0'), boxShadow: (isOpen ? '0 0 10px rgba(0,0,0,0.5)' : 'none') }}>
      <div 
        className={"music-card" + (isOpen ? ' music-card-open' : '') }
        id={id}
        onClick={() => isAuthenticated && setOpen(!isOpen)}
      >
        <div className="music-card-book">{item.book}</div>
        <div className="music-card-page">{item.page}</div>
        <div className="music-card-title">{item.title}</div>
        <div className="music-card-artist">{item.artist}</div>
      </div>
      {isOpen && isAuthenticated && (
        <div className="add-item-section">
          <div className="music-card">
            <div className="music-card-book">
              <textarea className="add-item-textarea" value={newBook} onChange={(e)=>setBook(e.target.value)}></textarea>
            </div>
            <div className="music-card-page">
              <textarea className="add-item-textarea" value={newPage} onChange={(e)=>setPage(e.target.value)}></textarea>
            </div>
            <div className="music-card-title">
              <textarea className="add-item-textarea" value={newTitle} onChange={(e)=>setTitle(e.target.value)}></textarea>
            </div>
            <div className="music-card-artist">
              <textarea className="add-item-textarea" value={newArtist} onChange={(e)=>setArtist(e.target.value)}></textarea>
            </div>
          </div>
          <div
            className="edit-item-button"
            onClick={()=> (!confirmEdit ? clickButton(setConfirmEdit) : updateItem()) }
          >
            {confirmEdit ? 'Confirm update' : 'Update item'}
          </div>
          <div
            className="delete-item-button"
            onClick={()=> (!confirmDelete ? clickButton(setConfirmDelete) : deleteItem()) }
          >
            {confirmDelete ? 'Confirm deletion' : 'Delete item'}
          </div>
        </div>
      )}
    </div>
  )
}

function LoginButton() {
  console.log(localStorage.getItem('isAuthenticated'));
  if (localStorage.getItem('isAuthenticated') === true) {
    return <div></div>
  }
  return (
    <div className="login-button" onClick={()=>OpenLoginWindow()}>Login to edit</div>
  )
}


function LoginWindow() {

  return (
    <div className="login-window" id="login1">
      <div className="login-header">
        <h3>Enter password to edit list</h3>
      </div>
      <textarea className="pass-entry" id="pass1"></textarea>
      <div className="pass-enter" onClick={()=>Authenticate()}>Enter</div>
      <div className="pass-attempt-message" id="pass-attempt-success">Success!</div>
      <div className="pass-attempt-message" id="pass-attempt-fail">The password was incorrect.</div>
    </div>
  )
}

function OpenLoginWindow() {
  document.getElementById("login1").style.display = "block";
}

function Authenticate() {
  const encrypted = "73aec99f8d8989b053e2bb7e1aab30694458d797b19a673249c46131a089c204";
  var passAttempt = document.getElementById("pass1").value;

  if (encrypted === sha256(passAttempt)) {
    localStorage.setItem('isAuthenticated', true);
    document.getElementById("pass-attempt-success").style.display = "block";
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    document.getElementById("pass-attempt-fail").style.display = "block";
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
}



function AddItemSection() {
  return (
    <div className="add-item-section">
      <div className="music-card">
        <div className="music-card-book">
          <textarea className="add-item-textarea" id="book-text"></textarea>
        </div>
        <div className="music-card-page">
          <textarea className="add-item-textarea" id="page-text"></textarea>
        </div>
        <div className="music-card-title">
          <textarea className="add-item-textarea" id="title-text"></textarea>
        </div>
        <div className="music-card-artist">
          <textarea className="add-item-textarea" id="artist-text"></textarea>
        </div>
      </div>
      <div className="add-item-button" onClick={()=>AddItem()}>+ Add Item</div>
    </div>
  )
}


function AddItem() {
  const book = document.getElementById("book-text").value;
  const page = document.getElementById("page-text").value;
  const title = document.getElementById("title-text").value;
  const artist = document.getElementById("artist-text").value;

  if (book === "" || page === "") {
    console.log("need book and page number");
    return;
  }

  if (title === "") {
    console.log("need a song title");
  }

  axios.post(
    'https://phils-music-list.herokuapp.com/api/music/addNew',
    {
      "Book": parseInt(book),
      "Page": parseInt(page),
      "Title": title,
      "Author": artist
    }
  ).then((res) => {
    console.log(res);
  })
}